















































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import NoData from '@/components/datadisplay/NoData.vue';

// Components
import StepperNavigation from '@/components/zones/stepperNavigation.vue';
import LoadingData from '@/components/datadisplay/LoadingData.vue';

// State module imports
import { ZonesModule } from '@/store/zones/zones';
import { AuthStore } from '@/store/auth/auth';

// Classes
import { ISupplierZonePrice } from '@/classes/supplierZones/ISupplierZonePrice';
import { SupplierZonePrice } from '@/classes/supplierZones/supplierZonePrice';
import { ISupplierZoneException } from '@/classes/supplierZones/ISupplierZoneException';

// Service Imports
import { SupplierZoneServicesService } from '@/services/supplierZones/supplierZoneServicesService';

@Component({
	components: { StepperNavigation, NoData, LoadingData },
})
export default class SurchargeOrExclusions extends Vue {
	selectedPrices: ISupplierZonePrice[] = [];
	selectedPrice: ISupplierZonePrice = new SupplierZonePrice();
	selectedExceptions: ISupplierZoneException[] = [];
	isLoading = true;

	//Exception - Surchage Props
	surchargePostcode = '';
	surchargePrice: number | null = null;
	exclusionPostcode = '';

	//Services
	supplierZoneServicesService = new SupplierZoneServicesService();

	// Modules
	zonesModule = ZonesModule;
	authModule = AuthStore;

	// Pricing Form Validity
	isSurchargeValid = false;

	Save() {
		this.$router.push(`/zones/${this.$route.params.zoneId}/complete`);
	}

	created() {
		if (this.zonesModule.GetGroupedZonePrices.length === 0) {
			this.supplierZoneServicesService
				.GetSupplierZonePricesGrouped(Number(this.$route.params.zoneId))
				.then(data => {
					if (data[0] && data[0].ServicePrices.length > 0) {
						this.selectedPrices = data[0].ServicePrices;
					}
					this.zonesModule.UpdateGroupedZonePrices(data).then(data => {
						this.isLoading = false;
					});
				});
		} else {
			if (this.zonesModule.GetGroupedZonePrices[0].ServicePrices.length > 0) {
				this.selectedPrices = this.zonesModule.GetGroupedZonePrices[0].ServicePrices;
			}
			this.isLoading = false;
		}
	}

	SelectPrice(price: ISupplierZonePrice) {
		this.selectedExceptions = price.Exceptions;
		this.selectedPrice = price;
	}

	AddSurchargeExclusion() {
		const surOrExc = {
			Postcode: this.surchargePostcode || this.exclusionPostcode,
			IsExcluded: this.exclusionPostcode ? true : false,
			Surcharge: this.surchargePrice || 0,
			ZonePriceId: this.selectedPrice.Id,
		} as ISupplierZoneException;

		this.supplierZoneServicesService
			.AddSurchargeExclusion(surOrExc)
			.then(surchargeOrExclusion => {
				this.selectedPrice.Exceptions.push(surchargeOrExclusion);
				this.surchargePostcode = '';
				this.exclusionPostcode = '';
				this.surchargePrice = null;
				(this.$refs.surchargeForm as any).resetValidation();
			});
	}
	DeleteSurchargeExclusion(id: number | undefined) {
		// Update Selected exceptions to exclude recently deleted exclusion/surcharge
		this.selectedPrice.Exceptions = this.selectedPrice?.Exceptions.filter(
			xx => xx.Id !== id
		);

		//	Update the price object to remove it from parent item
		// this.selectedPrices.find(
		// 	xx => xx.Id == this.selectedPrice.Id
		// ).Exceptions = this.selectedExceptions;
		if (id) {
			this.supplierZoneServicesService.DeleteSurchargeExclusion(id);
		}
	}

	SelectWasteStream(servicePrices: ISupplierZonePrice[]) {
		this.selectedPrices = servicePrices;

		this.selectedExceptions = [];
		this.selectedPrice = new SupplierZonePrice();
	}
}
